import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(app)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/config/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationProvider"] */ "/app/src/providers/app/AuthenticationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EnvironmentProvider"] */ "/app/src/providers/app/EnvironmentProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/app/FacebookSdkProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/app/FirebaseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingWindowProvider"] */ "/app/src/providers/app/FloatingWindowProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalAppStateProvider"] */ "/app/src/providers/app/GlobalAppStateProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalConfigProvider"] */ "/app/src/providers/app/GlobalConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/ReactQueryProvider.tsx");
